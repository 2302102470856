import React from 'react';
import './App.css';
import "./ComingSoon.css"; // Import CSS for styling

function App() {
    return (
        <div className="container">
            <img src="/Logo.png" alt="Third Space Market Logo" />
            <h1>Coming Soon!</h1>
            <p>
                Third Space Market in Gorham, ME is a specialty food and gift shop,
                with co-working & community gathering space.
            </p>
             <div className="social-links">
                <a
                    href="https://www.instagram.com/thirdspacemarket/#"
                    class="fa fa-instagram" target="_blank" rel="noopener noreferrer">
                </a>
                <a
                    href="https://facebook.com/ThirdSpaceMarket"
                    class="fa fa-facebook" target="_blank" rel="noopener noreferrer">
                </a>
            </div>
            <p>
                <strong>Planned Opening: April 2025!</strong>
            </p>
            <p>       We are looking to build vendor relationships. If you are interested in a partnership, 
            
            <div class="form-link">
    <a href="https://forms.gle/EbmHjCcVHAMbHodP9" target="_blank" rel="noopener noreferrer" class="button">
 please fill out this form.
    </a></div></p>

            
           
        </div>
    );
}

export default App;
